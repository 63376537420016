.wrapper {
  color: var(--brand-medium);
  display: flex;
  flex-direction: column;
  font-size: 10px;
  margin: var(--large-spacing) 0 0;
  padding: var(--base-spacing);
  text-transform: uppercase;
}

.wrapper a {
  color: var(--brand-medium);
  text-decoration: none;
}

.audio,
.brands {
  align-items: baseline;
  font-weight: 500;
  display: flex;
  margin: 0 0 var(--small-spacing);
}

.audio.online {
  color: var(--brand-primary);
}

.brands a {
  display: block;
  margin: 0 0 0 var(--small-spacing);
}

.brands a:first-child {
  margin: 0;
}

.song {
  margin: 0 0 0 var(--extra-small-spacing);
}

@media only screen and (min-width: 768px) {
  .wrapper {
    align-items: center;
    flex-direction: row-reverse;
    margin: 0;
    padding: var(--large-spacing);
  }

  .copyright {
    flex: 1;
  }

  .social {
    align-items: center;
    display: flex;
  }

  .audio,
  .brands {
    margin: 0 0 0 var(--base-spacing);
  }
}

html,
body {
  height: 100%;
}

body {
  background: var(--brand-gradient);
  background-attachment: fixed;
  background-size: cover;
  color: var(--text-color);
  font-family: var(--font-family);
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.no-scroll {
  overflow: hidden;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  margin: 0 0 var(--small-spacing);
}

p {
  line-height: 24px;
  margin: 0 0 var(--small-spacing);
}

a {
  color: var(--brand-primary);
}

a:focus {
  outline: var(--brand-focus);
  outline-offset: 2px;
}

svg {
  display: block;
}

label {
  color: var(--brand-light);
  display: block;
  margin: 0 0 var(--extra-small-spacing);
}

button {
  align-items: center;
  appearance: none;
  background: none;
  border: none;
  color: var(--brand-primary);
  cursor: pointer;
  display: flex;
  font-size: 14px;
  justify-content: center;
  padding: 0;
}

button:disabled {
  opacity: 0.6;
}

button:focus {
  outline: var(--brand-focus);
  outline-offset: 2px;
}

input,
textarea {
  appearance: none;
  background: none;
  border: none;
  border-bottom: 2px solid var(--brand-medium);
  color: var(--white);
  display: block;
  font-size: 14px;
  height: 40px;
  margin: 0 0 var(--base-spacing);
  max-width: 100%;
  padding: 0;
  resize: none;
  width: 420px;
}

textarea {
  height: 105px;
}

input:focus,
textarea:focus {
  border-color: var(--brand-primary);
  outline: none;
}

input:disabled,
textarea:disabled {
  border-color: var(--brand-medium);
  opacity: 0.6;
}

#g-recaptcha {
  margin: 0 0 var(--base-spacing);
}

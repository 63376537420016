.wrapper {
  flex: 1;
  padding: 0 var(--base-spacing);
  max-width: 600px;
}

@media only screen and (min-width: 768px) {
  .wrapper {
    padding: 0 var(--large-spacing);
  }
}

.wrapper {
  align-items: center;
  background: none;
  display: flex;
  flex-direction: column;
  font-size: 24px;
  font-weight: 500;
  justify-content: center;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.wrapper[data-state='entering'],
.wrapper[data-state='entered'] {
  animation: navShow 200ms cubic-bezier(0, 0, 0.2, 1) both;
}

.wrapper[data-state='exiting'] {
  animation: navHide 200ms cubic-bezier(0.4, 0, 1, 1) both;
}

.header {
  padding: var(--base-spacing);
  position: absolute;
  top: 0;
  right: 0;
}

.header-button {
  padding: 0;
  width: 40px;
  height: 40px;
}

.title,
.header-button-label {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list li {
  margin: var(--base-spacing) 0;
}

.list a {
  text-decoration: none;
}

.list a span {
  font-style: italic;
  font-weight: 400;
}

.disabled {
  color: var(--brand-medium);
  cursor: default;
}

.disabled:focus {
  outline: none;
}

@media only screen and (min-width: 768px) {
  .header {
    padding: var(--large-spacing);
  }
}

@keyframes navShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes navHide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

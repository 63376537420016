.wrapper {
  align-items: center;
  background: var(--brand-primary);
  border-radius: 3px;
  color: var(--brand-base);
  display: inline-flex;
  font-size: 14px;
  font-weight: 500;
  padding: var(--small-spacing);
}

.wrapper span {
  display: block;
  margin: 0 0 0 var(--extra-small-spacing);
}

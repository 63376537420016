.wrapper {
  align-items: center;
  display: flex;
  padding: var(--base-spacing);
}

.section-left {
  flex: 1;
}

.logo {
  display: block;
  height: 40px;
  width: 36px;
}

.logo:focus {
  outline: var(--brand-focus);
  outline-offset: 2px;
}

.logo img {
  display: block;
  height: 40px;
  width: 36px;
}

.logo:hover img {
  animation: rotate 1s cubic-bezier(0.175, 0.885, 0.32, 1.175);
}

.brand {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}

.menu-button {
  padding: 0;
  width: 40px;
  height: 40px;
}

.menu-button-label {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}

@media only screen and (min-width: 768px) {
  .wrapper {
    padding: var(--large-spacing);
  }
}

@keyframes rotate {
  from {
    transform: rotate3d(0, 1, 0, 0deg);
  }
  to {
    transform: rotate3d(0, 1, 0, 360deg);
  }
}

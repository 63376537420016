:root {
  --white: #ffffff;
  --brand-primary: #ffd700;
  --brand-base: #303030;
  --brand-dark: #101010;
  --brand-medium: #999999;
  --brand-light: #cccccc;
  --brand-focus: 2px solid var(--brand-primary);
  --brand-gradient: radial-gradient(
      ellipse at top left,
      var(--brand-base),
      var(--brand-dark)
    )
    top left no-repeat fixed;
  --brand-transition: 150ms ease;
  --font-family: frank-new, sans-serif;
  --font-size: 12px;
  --font-weight: 400;
  --text-color: var(--white);
  --extra-small-spacing: 8px;
  --small-spacing: 16px;
  --base-spacing: 32px;
  --large-spacing: 64px;
}

.wrapper {
  backdrop-filter: blur(4px);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
}

.wrapper::before {
  background: var(--brand-gradient);
  content: ' ';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.wrapper[data-state='entering']::before,
.wrapper[data-state='entered']::before {
  animation: overlayShow 240ms cubic-bezier(0, 0, 0.2, 1) both;
}

.wrapper[data-state='exiting']::before,
.wrapper[data-state='exited']::before {
  animation: overlayHide 240ms cubic-bezier(0.4, 0, 1, 1) both;
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.8;
  }
}

@keyframes overlayHide {
  from {
    opacity: 0.8;
  }
  to {
    opacity: 0;
  }
}

.wrapper {
  align-items: flex-end;
  display: flex;
  flex-shrink: 0;
  overflow: hidden;
  width: 20px;
  height: 12px;
}

.column {
  flex: 1;
  position: relative;
  margin-right: 1px;
  height: 100%;
}

.bar {
  background-color: var(--brand-medium);
  position: absolute;
  transform: translateY(calc(100% - 1px));
  will-change: transform;
  width: 100%;
  height: 100%;
}

.active .bar {
  background-color: var(--brand-primary);
  animation-name: equalizer;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.bar[data-bar='1-1'] {
  animation-duration: 0.3s;
}

.bar[data-bar='1-2'] {
  animation-duration: 0.45s;
}

.bar[data-bar='2-1'] {
  animation-duration: 0.5s;
}

.bar[data-bar='2-2'] {
  animation-duration: 0.4s;
}

.bar[data-bar='3-1'] {
  animation-duration: 0.3s;
}

.bar[data-bar='3-2'] {
  animation-duration: 0.35s;
}

.bar[data-bar='4-1'] {
  animation-duration: 0.4s;
}

.bar[data-bar='4-2'] {
  animation-duration: 0.25s;
}

@keyframes equalizer {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

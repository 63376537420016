@import url(https://use.typekit.net/byq2mqb.css);
.page_wrapper__36nj1 {
  flex: 1 1;
  padding: 0 var(--base-spacing);
  max-width: 600px;
}

@media only screen and (min-width: 768px) {
  .page_wrapper__36nj1 {
    padding: 0 var(--large-spacing);
  }
}

.alert_wrapper__2lr-b {
  align-items: center;
  background: var(--brand-primary);
  border-radius: 3px;
  color: var(--brand-base);
  display: inline-flex;
  font-size: 14px;
  font-weight: 500;
  padding: var(--small-spacing);
}

.alert_wrapper__2lr-b span {
  display: block;
  margin: 0 0 0 var(--extra-small-spacing);
}

.overlay_wrapper__1vnOv {
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
}

.overlay_wrapper__1vnOv::before {
  background: var(--brand-gradient);
  content: ' ';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.overlay_wrapper__1vnOv[data-state='entering']::before,
.overlay_wrapper__1vnOv[data-state='entered']::before {
  -webkit-animation: overlay_overlayShow__V-vrj 240ms cubic-bezier(0, 0, 0.2, 1) both;
          animation: overlay_overlayShow__V-vrj 240ms cubic-bezier(0, 0, 0.2, 1) both;
}

.overlay_wrapper__1vnOv[data-state='exiting']::before,
.overlay_wrapper__1vnOv[data-state='exited']::before {
  -webkit-animation: overlay_overlayHide__1m9t- 240ms cubic-bezier(0.4, 0, 1, 1) both;
          animation: overlay_overlayHide__1m9t- 240ms cubic-bezier(0.4, 0, 1, 1) both;
}

@-webkit-keyframes overlay_overlayShow__V-vrj {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.8;
  }
}

@keyframes overlay_overlayShow__V-vrj {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.8;
  }
}

@-webkit-keyframes overlay_overlayHide__1m9t- {
  from {
    opacity: 0.8;
  }
  to {
    opacity: 0;
  }
}

@keyframes overlay_overlayHide__1m9t- {
  from {
    opacity: 0.8;
  }
  to {
    opacity: 0;
  }
}

.nav_wrapper__MZBEh {
  align-items: center;
  background: none;
  display: flex;
  flex-direction: column;
  font-size: 24px;
  font-weight: 500;
  justify-content: center;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.nav_wrapper__MZBEh[data-state='entering'],
.nav_wrapper__MZBEh[data-state='entered'] {
  -webkit-animation: nav_navShow__2LaqL 200ms cubic-bezier(0, 0, 0.2, 1) both;
          animation: nav_navShow__2LaqL 200ms cubic-bezier(0, 0, 0.2, 1) both;
}

.nav_wrapper__MZBEh[data-state='exiting'] {
  -webkit-animation: nav_navHide__3rgd2 200ms cubic-bezier(0.4, 0, 1, 1) both;
          animation: nav_navHide__3rgd2 200ms cubic-bezier(0.4, 0, 1, 1) both;
}

.nav_header__2dxn9 {
  padding: var(--base-spacing);
  position: absolute;
  top: 0;
  right: 0;
}

.nav_header-button__14OFH {
  padding: 0;
  width: 40px;
  height: 40px;
}

.nav_title__1Ugg_,
.nav_header-button-label__nD2Xx {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}

.nav_list__D21YN {
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav_list__D21YN li {
  margin: var(--base-spacing) 0;
}

.nav_list__D21YN a {
  text-decoration: none;
}

.nav_list__D21YN a span {
  font-style: italic;
  font-weight: 400;
}

.nav_disabled__2cb1v {
  color: var(--brand-medium);
  cursor: default;
}

.nav_disabled__2cb1v:focus {
  outline: none;
}

@media only screen and (min-width: 768px) {
  .nav_header__2dxn9 {
    padding: var(--large-spacing);
  }
}

@-webkit-keyframes nav_navShow__2LaqL {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes nav_navShow__2LaqL {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes nav_navHide__3rgd2 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes nav_navHide__3rgd2 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.header_wrapper__3nDU2 {
  align-items: center;
  display: flex;
  padding: var(--base-spacing);
}

.header_section-left__1_7P8 {
  flex: 1 1;
}

.header_logo__3yqbM {
  display: block;
  height: 40px;
  width: 36px;
}

.header_logo__3yqbM:focus {
  outline: var(--brand-focus);
  outline-offset: 2px;
}

.header_logo__3yqbM img {
  display: block;
  height: 40px;
  width: 36px;
}

.header_logo__3yqbM:hover img {
  -webkit-animation: header_rotate__Yy8Di 1s cubic-bezier(0.175, 0.885, 0.32, 1.175);
          animation: header_rotate__Yy8Di 1s cubic-bezier(0.175, 0.885, 0.32, 1.175);
}

.header_brand__26gWA {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}

.header_menu-button__3lM4L {
  padding: 0;
  width: 40px;
  height: 40px;
}

.header_menu-button-label__ZzjDj {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}

@media only screen and (min-width: 768px) {
  .header_wrapper__3nDU2 {
    padding: var(--large-spacing);
  }
}

@-webkit-keyframes header_rotate__Yy8Di {
  from {
    -webkit-transform: rotate3d(0, 1, 0, 0deg);
            transform: rotate3d(0, 1, 0, 0deg);
  }
  to {
    -webkit-transform: rotate3d(0, 1, 0, 360deg);
            transform: rotate3d(0, 1, 0, 360deg);
  }
}

@keyframes header_rotate__Yy8Di {
  from {
    -webkit-transform: rotate3d(0, 1, 0, 0deg);
            transform: rotate3d(0, 1, 0, 0deg);
  }
  to {
    -webkit-transform: rotate3d(0, 1, 0, 360deg);
            transform: rotate3d(0, 1, 0, 360deg);
  }
}

.equalizer_wrapper__18oRE {
  align-items: flex-end;
  display: flex;
  flex-shrink: 0;
  overflow: hidden;
  width: 20px;
  height: 12px;
}

.equalizer_column__1YExh {
  flex: 1 1;
  position: relative;
  margin-right: 1px;
  height: 100%;
}

.equalizer_bar__3BTN- {
  background-color: var(--brand-medium);
  position: absolute;
  -webkit-transform: translateY(calc(100% - 1px));
          transform: translateY(calc(100% - 1px));
  will-change: transform;
  width: 100%;
  height: 100%;
}

.equalizer_active__2T19T .equalizer_bar__3BTN- {
  background-color: var(--brand-primary);
  -webkit-animation-name: equalizer_equalizer__2bDAr;
          animation-name: equalizer_equalizer__2bDAr;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
}

.equalizer_bar__3BTN-[data-bar='1-1'] {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
}

.equalizer_bar__3BTN-[data-bar='1-2'] {
  -webkit-animation-duration: 0.45s;
          animation-duration: 0.45s;
}

.equalizer_bar__3BTN-[data-bar='2-1'] {
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
}

.equalizer_bar__3BTN-[data-bar='2-2'] {
  -webkit-animation-duration: 0.4s;
          animation-duration: 0.4s;
}

.equalizer_bar__3BTN-[data-bar='3-1'] {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
}

.equalizer_bar__3BTN-[data-bar='3-2'] {
  -webkit-animation-duration: 0.35s;
          animation-duration: 0.35s;
}

.equalizer_bar__3BTN-[data-bar='4-1'] {
  -webkit-animation-duration: 0.4s;
          animation-duration: 0.4s;
}

.equalizer_bar__3BTN-[data-bar='4-2'] {
  -webkit-animation-duration: 0.25s;
          animation-duration: 0.25s;
}

@-webkit-keyframes equalizer_equalizer__2bDAr {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes equalizer_equalizer__2bDAr {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.footer_wrapper__EQAfZ {
  color: var(--brand-medium);
  display: flex;
  flex-direction: column;
  font-size: 10px;
  margin: var(--large-spacing) 0 0;
  padding: var(--base-spacing);
  text-transform: uppercase;
}

.footer_wrapper__EQAfZ a {
  color: var(--brand-medium);
  text-decoration: none;
}

.footer_audio__2KP_G,
.footer_brands__3_YED {
  align-items: baseline;
  font-weight: 500;
  display: flex;
  margin: 0 0 var(--small-spacing);
}

.footer_audio__2KP_G.footer_online__2Q6zq {
  color: var(--brand-primary);
}

.footer_brands__3_YED a {
  display: block;
  margin: 0 0 0 var(--small-spacing);
}

.footer_brands__3_YED a:first-child {
  margin: 0;
}

.footer_song__30EyZ {
  margin: 0 0 0 var(--extra-small-spacing);
}

@media only screen and (min-width: 768px) {
  .footer_wrapper__EQAfZ {
    align-items: center;
    flex-direction: row-reverse;
    margin: 0;
    padding: var(--large-spacing);
  }

  .footer_copyright__zfoV1 {
    flex: 1 1;
  }

  .footer_social__3HbO7 {
    align-items: center;
    display: flex;
  }

  .footer_audio__2KP_G,
  .footer_brands__3_YED {
    margin: 0 0 0 var(--base-spacing);
  }
}

.app_wrapper__1gdeM {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}



:root {
  --white: #ffffff;
  --brand-primary: #ffd700;
  --brand-base: #303030;
  --brand-dark: #101010;
  --brand-medium: #999999;
  --brand-light: #cccccc;
  --brand-focus: 2px solid var(--brand-primary);
  --brand-gradient: radial-gradient(
      ellipse at top left,
      var(--brand-base),
      var(--brand-dark)
    )
    top left no-repeat fixed;
  --brand-transition: 150ms ease;
  --font-family: frank-new, sans-serif;
  --font-size: 12px;
  --font-weight: 400;
  --text-color: var(--white);
  --extra-small-spacing: 8px;
  --small-spacing: 16px;
  --base-spacing: 32px;
  --large-spacing: 64px;
}

html,
body {
  height: 100%;
}

body {
  background: var(--brand-gradient);
  background-attachment: fixed;
  background-size: cover;
  color: var(--text-color);
  font-family: var(--font-family);
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.no-scroll {
  overflow: hidden;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  margin: 0 0 var(--small-spacing);
}

p {
  line-height: 24px;
  margin: 0 0 var(--small-spacing);
}

a {
  color: var(--brand-primary);
}

a:focus {
  outline: var(--brand-focus);
  outline-offset: 2px;
}

svg {
  display: block;
}

label {
  color: var(--brand-light);
  display: block;
  margin: 0 0 var(--extra-small-spacing);
}

button {
  align-items: center;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: none;
  border: none;
  color: var(--brand-primary);
  cursor: pointer;
  display: flex;
  font-size: 14px;
  justify-content: center;
  padding: 0;
}

button:disabled {
  opacity: 0.6;
}

button:focus {
  outline: var(--brand-focus);
  outline-offset: 2px;
}

input,
textarea {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: none;
  border: none;
  border-bottom: 2px solid var(--brand-medium);
  color: var(--white);
  display: block;
  font-size: 14px;
  height: 40px;
  margin: 0 0 var(--base-spacing);
  max-width: 100%;
  padding: 0;
  resize: none;
  width: 420px;
}

textarea {
  height: 105px;
}

input:focus,
textarea:focus {
  border-color: var(--brand-primary);
  outline: none;
}

input:disabled,
textarea:disabled {
  border-color: var(--brand-medium);
  opacity: 0.6;
}

#g-recaptcha {
  margin: 0 0 var(--base-spacing);
}




